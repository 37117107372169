import React from 'react';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../../../../medreview/server/src';
import { trpc } from '../../../../../../../platform/app/trpc';
import { notifications } from '@mantine/notifications';
import { Tooltip, ActionIcon } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';

export default function DownloadPdfBtn({
  conclusion,
  study,
}: {
  conclusion?:
    | NonNullable<inferRouterOutputs<AppRouter>['study']['get']>['pre_conclusion'][number]
    | NonNullable<inferRouterOutputs<AppRouter>['study']['get']>['conclusion'][number];
  study?: inferRouterOutputs<AppRouter>['study']['get'] | null;
}) {
  const { refetch: refetchPdfUrl } = trpc.study.getPdf.useQuery(
    {
      id: (conclusion ? conclusion.id : study?.pre_conclusion?.[0]?.id) as number,
      type: conclusion ? 'conclusion' : 'pre_conclusion',
    },
    { enabled: !!conclusion?.id || !!study?.pre_conclusion[0]?.id }
  );

  const downloadPdfHandler = async () => {
    const { data } = await refetchPdfUrl();

    if (data?.conclusion_url) {
      const link = document.createElement('a');
      link.href = `${process.env.S3_URL + '/' + data?.conclusion_url}`;
      link.target = '_blank';
      link.download = 'Заключение.pdf';
      link.dispatchEvent(new MouseEvent('click'));
    } else {
      notifications.show({
        message: 'PDF заключения на стадии генерации, повторите действие через 1 минуту.',
        color: 'orange',
      });
    }
  };
  return (
    <>
      {(conclusion || study?.pre_conclusion[0]) && (
        <ActionIcon
          variant="filled"
          onClick={downloadPdfHandler}
          className="p-[2px]"
        >
          <Tooltip label="Загрузить PDF">
            <IconDownload />
          </Tooltip>
        </ActionIcon>
      )}
    </>
  );
}
