import React from 'react';
import CnMammo from './CnMammo';
import CnDefault from './CnDefault';
import { useGetStudy } from '../../../../../platform/app/src/api/studies';

export default function PanelConclusion({
  studyId,
  onSave,
}: {
  studyId?: number;
  onSave?: () => void;
}) {
  const study = useGetStudy(studyId, !!studyId);

  if (!study.data) {
    return null;
  }

  return study?.data.modality_study.some(ms => ms.modalities.name === 'MG') ? (
    <CnMammo />
  ) : (
    <CnDefault
      study={study.data}
      onSave={() => onSave && onSave()}
    />
  );
}
