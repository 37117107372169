import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useModal } from '../../contextProviders';

import Icon from '../Icon';
import Typography from '../Typography';

import './Modal.css';

if (typeof document !== 'undefined') {
  ReactModal.setAppElement(document.getElementById('root'));
}

const Modal = ({
  closeButton,
  shouldCloseOnEsc,
  isOpen,
  title,
  onClose,
  children,
  shouldCloseOnOverlayClick,
  footerTemplate,
  size,
  onModalClose,
}) => {
  const { hide } = useModal();

  const handleClose = () => {
    hide();
  };

  const renderHeader = () => {
    return (
      title && (
        <header className="bg-secondary-dark flex items-center rounded-tl rounded-tr border-b-2 border-black p-4">
          <Typography
            variant="h4"
            className="text-primary-light flex grow font-light"
          >
            {title}
          </Typography>
          {closeButton && (
            <Icon
              onClick={onClose}
              name="close"
              className="text-primary-active cursor-pointer"
            />
          )}
        </header>
      )
    );
  };

  return (
    <ReactModal
      className={`relative ${
        size === 'wide' ? 'w-11/12 lg:w-10/12 xl:w-10/12' : 'w-11/12 lg:w-10/12 xl:w-1/2'
      } max-h-full text-white  outline-none`}
      overlayClassName="fixed top-0 left-0 right-0 bottom-0 z-[9999] bg-overlay flex items-start justify-center py-16"
      shouldCloseOnEsc={shouldCloseOnEsc}
      onRequestClose={handleClose}
      isOpen={isOpen}
      title={title}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onAfterClose={onModalClose}
    >
      {renderHeader()}
      <section className="ohif-scrollbar modal-content bg-primary-dark overflow-y-auto rounded-bl rounded-br px-4 py-6">
        {children}
      </section>

      {footerTemplate && (
        <footer className="bg-secondary-dark flex items-center rounded-tl rounded-tr border-b-2 border-black p-4">
          {footerTemplate}
        </footer>
      )}
    </ReactModal>
  );
};

Modal.defaultProps = {
  shouldCloseOnEsc: true,
  shouldCloseOnOverlayClick: true,
};

Modal.propTypes = {
  closeButton: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  /** The modal's content */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
};

export default Modal;
