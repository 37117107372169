import { servicesManager } from '../app/src/App';
import axios from 'axios';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../medreview/server/src';

export async function getOptimalDataSource(
  orthancConfig:
    | NonNullable<
        NonNullable<inferRouterOutputs<AppRouter>['study']['get']>['organizations']
      >['orthanc_config'][number]
    | null,
  userOrganizations: NonNullable<
    NonNullable<inferRouterOutputs<AppRouter>['general']['getUser']>['organization_user']
  >,
  studyUid: string | null
) {
  const localDataSource = orthancConfig?.dicom_hostname;
  const proxyVpnDataSource = orthancConfig?.local_dicomweb_proxy;
  const mlDataSource = `${process.env.ML_ORTHANC_URL}/pacs`;
  const cloudDataSource = `${process.env.MR_OLD_URL}/pacs`;

  if (studyUid && !localDataSource) {
    localStorage.removeItem('localOrthanc');
  }

  if (
    localStorage.getItem('localOrthanc') &&
    localDataSource &&
    (await checkDataSource(localDataSource, studyUid))
  ) {
    return localDataSource;
  }

  if (proxyVpnDataSource && (await checkDataSource(proxyVpnDataSource, studyUid))) {
    return proxyVpnDataSource;
  }

  return userOrganizations.some(o => o.organizations?.name === 'ml_test')
    ? mlDataSource
    : cloudDataSource;
}

export async function checkDataSource(url: string, studyUid: string | null) {
  let isOk = false;

  // TODO: wait for the types oh OHIF services
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { userAuthenticationService } = servicesManager.services as any;

  try {
    const res = studyUid
      ? await axios.post(`${url}/tools/lookup`, studyUid, {
          headers: userAuthenticationService?.getAuthorizationHeader(),
          timeout: 5000,
        })
      : await axios.get(`${url}/tools/now-local`, {
          headers: userAuthenticationService?.getAuthorizationHeader(),
        });

    isOk = Array.isArray(res.data) ? res.data.length > 0 : !!res.data;
  } catch (error) {
    isOk = false;
    console.log(error);
  }

  return isOk;
}
