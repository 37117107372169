import { getStoredOrDefaultMouseBindings } from '../../../platform/core/src/defaults/hotkeyBindings';

function initDefaultToolGroup(
  extensionManager,
  toolGroupService,
  commandsManager,
  toolGroupId,
  mouseBindingTools
) {
  const utilityModule = extensionManager.getModuleEntry(
    '@ohif/extension-cornerstone.utilityModule.tools'
  );

  const { toolNames, Enums } = utilityModule.exports;

  const activeTools = [
    mouseBindingTools.primary,
    mouseBindingTools.auxiliary,
    mouseBindingTools.secondary,
  ];

  const tools = {
    active: [
      {
        toolName: toolNames[mouseBindingTools.primary],
        bindings: [{ mouseButton: Enums.MouseBindings.Primary }],
      },
      {
        toolName: toolNames[mouseBindingTools.auxiliary],
        bindings: [{ mouseButton: Enums.MouseBindings.Auxiliary }],
      },
      {
        toolName: toolNames[mouseBindingTools.secondary],
        bindings: [{ mouseButton: Enums.MouseBindings.Secondary }],
      },
      { toolName: toolNames.StackScrollMouseWheel, bindings: [] },
    ],
    passive: [
      { toolName: toolNames.Length },
      {
        toolName: toolNames.ArrowAnnotate,
        configuration: {
          getTextCallback: (callback, eventDetails) =>
            commandsManager.runCommand('arrowTextCallback', {
              callback,
              eventDetails,
            }),

          changeTextCallback: (data, eventDetails, callback) =>
            commandsManager.runCommand('arrowTextCallback', {
              callback,
              data,
              eventDetails,
            }),
        },
      },
      { toolName: toolNames.Bidirectional },
      { toolName: toolNames.DragProbe },
      { toolName: toolNames.EllipticalROI },
      { toolName: toolNames.CircleROI },
      { toolName: toolNames.RectangleROI },
      { toolName: toolNames.StackScroll },
      { toolName: toolNames.Angle },
      { toolName: toolNames.CobbAngle },
      { toolName: toolNames.PlanarFreehandROI },
      { toolName: toolNames.Magnify },
      { toolName: toolNames.SegmentationDisplay },
      { toolName: toolNames.CalibrationLine },
      { toolName: toolNames.Zoom },
      { toolName: toolNames.WindowLevel },
      { toolName: toolNames.Pan },
    ].filter(pt => !activeTools.some(at => at === pt.toolName)),
    // enabled
    enabled: [{ toolName: toolNames.ImageOverlayViewer }],
    // disabled
    disabled: [{ toolName: toolNames.ReferenceLines }],
  };

  const toolGroup = toolGroupService.getToolGroup(toolGroupId);

  if (toolGroup) {
    toolGroupService._setToolsMode(toolGroup, tools);
  } else {
    toolGroupService.createToolGroupAndAddTools(
      toolGroupId,
      tools
    );
  }
}

function initSRToolGroup(
  extensionManager,
  toolGroupService,
  commandsManager,
  mouseBindingTools
) {
  const SRUtilityModule = extensionManager.getModuleEntry(
    '@ohif/extension-cornerstone-dicom-sr.utilityModule.tools'
  );

  if (!SRUtilityModule) {
    return;
  }

  const CS3DUtilityModule = extensionManager.getModuleEntry(
    '@ohif/extension-cornerstone.utilityModule.tools'
  );

  const activeTools = [
    mouseBindingTools.primary,
    mouseBindingTools.auxiliary,
    mouseBindingTools.secondary,
  ];

  const { toolNames: SRToolNames } = SRUtilityModule.exports;
  const { toolNames, Enums } = CS3DUtilityModule.exports;
  const tools = {
    active: [
      {
        toolName: toolNames[mouseBindingTools.primary],
        bindings: [
          {
            mouseButton: Enums.MouseBindings.Primary,
          },
        ],
      },
      {
        toolName: toolNames[mouseBindingTools.auxiliary],
        bindings: [
          {
            mouseButton: Enums.MouseBindings.Auxiliary,
          },
        ],
      },
      {
        toolName: toolNames[mouseBindingTools.secondary],
        bindings: [
          {
            mouseButton: Enums.MouseBindings.Secondary,
          },
        ],
      },
      {
        toolName: toolNames.StackScrollMouseWheel,
        bindings: [],
      },
    ],
    passive: [
      { toolName: SRToolNames.SRLength },
      { toolName: SRToolNames.SRArrowAnnotate },
      { toolName: SRToolNames.SRBidirectional },
      { toolName: SRToolNames.SREllipticalROI },
      { toolName: SRToolNames.SRCircleROI },
    ].filter(pt => !activeTools.some(at => at === pt.toolName)),
    enabled: [
      {
        toolName: SRToolNames.DICOMSRDisplay,
        bindings: [],
      },
    ],
    // disabled
  };

  const toolGroupId = 'SRToolGroup';
  const toolGroup = toolGroupService.getToolGroup(toolGroupId);

  if (toolGroup) {
    toolGroupService._setToolsMode(toolGroup, tools);
  } else {
    toolGroupService.createToolGroupAndAddTools(
      toolGroupId,
      tools
    );
  }
}

function initMPRToolGroup(
  extensionManager,
  toolGroupService,
  commandsManager,
  mouseBindingTools
) {
  const utilityModule = extensionManager.getModuleEntry(
    '@ohif/extension-cornerstone.utilityModule.tools'
  );

  const activeTools = [
    mouseBindingTools.primary,
    mouseBindingTools.auxiliary,
    mouseBindingTools.secondary,
  ];

  const { toolNames, Enums } = utilityModule.exports;

  const tools = {
    active: [
      {
        toolName: toolNames[mouseBindingTools.primary],
        bindings: [{ mouseButton: Enums.MouseBindings.Primary }],
      },
      {
        toolName: toolNames[mouseBindingTools.auxiliary],
        bindings: [{ mouseButton: Enums.MouseBindings.Auxiliary }],
      },
      {
        toolName: toolNames[mouseBindingTools.secondary],
        bindings: [{ mouseButton: Enums.MouseBindings.Secondary }],
      },
      { toolName: toolNames.StackScrollMouseWheel, bindings: [] },
    ],
    passive: [
      { toolName: toolNames.Length },
      {
        toolName: toolNames.ArrowAnnotate,
        configuration: {
          getTextCallback: (callback, eventDetails) =>
            commandsManager.runCommand('arrowTextCallback', {
              callback,
              eventDetails,
            }),

          changeTextCallback: (data, eventDetails, callback) =>
            commandsManager.runCommand('arrowTextCallback', {
              callback,
              data,
              eventDetails,
            }),
        },
      },
      { toolName: toolNames.Bidirectional },
      { toolName: toolNames.DragProbe },
      { toolName: toolNames.EllipticalROI },
      { toolName: toolNames.CircleROI },
      { toolName: toolNames.RectangleROI },
      { toolName: toolNames.StackScroll },
      { toolName: toolNames.Angle },
      { toolName: toolNames.CobbAngle },
      { toolName: toolNames.PlanarFreehandROI },
      { toolName: toolNames.SegmentationDisplay },
      { toolName: toolNames.Zoom },
      { toolName: toolNames.WindowLevel },
      { toolName: toolNames.Pan },
    ].filter(pt => !activeTools.some(at => at === pt.toolName)),
    disabled: [
      {
        toolName: toolNames.Crosshairs,
        configuration: {
          viewportIndicators: false,
          autoPan: {
            enabled: false,
            panSize: 10,
          },
        },
      },
      { toolName: toolNames.ReferenceLines },
    ],

    // enabled
    // disabled
  };

  const toolGroup = toolGroupService.getToolGroup('mpr');

  if (toolGroup) {
    toolGroupService._setToolsMode(toolGroup, tools);
  } else {
    toolGroupService.createToolGroupAndAddTools('mpr', tools);
  }
}
function initVolume3DToolGroup(
  extensionManager,
  toolGroupService,
  mouseBindingTools
) {
  const utilityModule = extensionManager.getModuleEntry(
    '@ohif/extension-cornerstone.utilityModule.tools'
  );

  const { toolNames, Enums } = utilityModule.exports;

  const tools = {
    active: [
      {
        toolName: toolNames[mouseBindingTools.primary],
        bindings: [{ mouseButton: Enums.MouseBindings.Primary }],
      },
      {
        toolName: toolNames[mouseBindingTools.auxiliary],
        bindings: [{ mouseButton: Enums.MouseBindings.Auxiliary }],
      },
      {
        toolName: toolNames[mouseBindingTools.secondary],
        bindings: [{ mouseButton: Enums.MouseBindings.Secondary }],
      },
    ],
  };

  const toolGroup = toolGroupService.getToolGroup('volume3d');

  if (toolGroup) {
    toolGroupService._setToolsMode(toolGroup, tools);
  } else {
    toolGroupService.createToolGroupAndAddTools('volume3d', tools);
  }
}

function initToolGroups(
  extensionManager,
  toolGroupService,
  commandsManager,
  mouseBindingTools = {}
) {
  const storedOrDefaultMouseBindings = getStoredOrDefaultMouseBindings();

  initDefaultToolGroup(
    extensionManager,
    toolGroupService,
    commandsManager,
    'default',
    {
      ...storedOrDefaultMouseBindings.default,
      ...mouseBindingTools,
    }
  );
  initSRToolGroup(extensionManager, toolGroupService, commandsManager, {
    ...storedOrDefaultMouseBindings.SRToolGroup,
    ...mouseBindingTools,
  });
  initMPRToolGroup(extensionManager, toolGroupService, commandsManager, {
    ...storedOrDefaultMouseBindings.mpr,
    ...mouseBindingTools,
  });
  initVolume3DToolGroup(extensionManager, toolGroupService, {
    ...storedOrDefaultMouseBindings.volume3d,
    ...mouseBindingTools,
  });
}

export default initToolGroups;
