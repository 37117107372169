import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@mantine/core';
import { useFormContext } from 'react-hook-form';
import { CnFirstMammoForm, CnMammoForm } from '../../CnMammo';
import { PreviewMammo, PreviewDefault } from '../CnPreview/PreviewMammo';
import type { AppRouter } from '../../../../../../../../medreview/server/src';
import { inferRouterOutputs } from '@trpc/server';
import { trpc } from '../../../../../../../platform/app/trpc';
import { modals } from '@mantine/modals';
import { DefaultForm } from '../../CnDefault';
import type { UseFormReturn } from 'react-hook-form';

export function SubmitCnBtn({
  onSubmit,
  study,
  conclusion,
}: {
  onSubmit: (data: DefaultForm, sendMessage: boolean) => void;
  study: inferRouterOutputs<AppRouter>['study']['get'];
  conclusion?: NonNullable<inferRouterOutputs<AppRouter>['study']['get']>['conclusion'][number];
}) {
  const { handleSubmit, getValues, trigger } = useFormContext<DefaultForm>();

  return (
    <Button
      size="xs"
      onClick={async () => {
        (await trigger()) &&
          modals.open({
            children: (
              <>
                <PreviewDefault
                  formData={getValues()}
                  study={study!}
                />
                <SubmitBtns
                  study={study}
                  onSubmit={(send_message: boolean) =>
                    handleSubmit(data => onSubmit(data, send_message))()
                  }
                  conclusion={conclusion}
                />
              </>
            ),
            size: 1400,
            classNames: { body: 'p-0' },
          });
      }}
    >
      Сохранить
    </Button>
  );
}

export function SubmitMammoBtn({
  onSubmit,
  previewMetadata,
  study,
  conclusion,
}: {
  onSubmit: (data: CnMammoForm, sendMessage: boolean) => void;
  previewMetadata: {
    first: UseFormReturn<CnFirstMammoForm>;
    second: UseFormReturn<CnMammoForm>;
  };
  study: inferRouterOutputs<AppRouter>['study']['get'];
  conclusion?: NonNullable<inferRouterOutputs<AppRouter>['study']['get']>['conclusion'][number];
}) {
  const { handleSubmit, getValues, trigger, setError } = useFormContext<CnMammoForm>();
  const birads = trpc.general.getBirads.useQuery();

  return (
    <>
      <Button
        size="xs"
        onClick={async () => {
          const data = getValues();
          const criticalBirad = birads.data
            ?.filter(b => [data.birads_code_right, data.birads_code_left].includes(b.id.toString()))
            ?.find(b => b.category === 'M4' || b.category === 'M5');
          if (criticalBirad && !data.check_value) {
            setError('check_value', { message: 'Это обязательно поле' });
            return;
          }

          (await trigger()) &&
            modals.open({
              children: (
                <>
                  <PreviewMammo previewMetadata={previewMetadata} />
                  <SubmitBtns
                    study={study}
                    onSubmit={(send_message: boolean) =>
                      handleSubmit(data => onSubmit(data, send_message))()
                    }
                    conclusion={conclusion}
                  />
                </>
              ),
              size: 1400,
              classNames: { body: 'p-0' },
            });
        }}
      >
        Сохранить
      </Button>
    </>
  );
}

const SubmitBtns = ({
  study,
  conclusion,
  onSubmit,
}: {
  study?: inferRouterOutputs<AppRouter>['study']['get'];
  onSubmit: (sendMessage: boolean) => void;
  conclusion?: NonNullable<inferRouterOutputs<AppRouter>['study']['get']>['conclusion'][number];
}) => {
  const [sendMessage, setSendMessage] = useState<null | boolean>(null);
  const didMount = useRef(false);

  // we can't just call onSubmit on button click, because we need to show loading state before submitting.
  // React js doesn't have nextTick kind of function, so we have to use this workaround with useEffect
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    if (sendMessage === null) {
      return;
    }

    onSubmit(sendMessage);
  }, [onSubmit, sendMessage]);

  return (
    <div className="sticky bottom-0 flex justify-end gap-3 bg-[#041c4a] p-4">
      {(conclusion || !study?.ohif_id) && (
        <Button
          size="xs"
          loading={sendMessage !== null}
          onClick={() => setSendMessage(false)}
        >
          Отправить заключение
        </Button>
      )}

      {study?.ohif_id && (
        <Button
          size="xs"
          loading={sendMessage !== null}
          onClick={() => setSendMessage(true)}
        >
          {conclusion ? 'Отправить заключение и WhatsApp сообщение' : 'Подтвердить и отправить'}
        </Button>
      )}

      <Button
        size="xs"
        onClick={() => modals.closeAll()}
      >
        Отменить
      </Button>
    </div>
  );
};
