import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';
import Tooltip from '../Tooltip';
import Accordion from '../../../../components/Accordion';
import { useGetStudy } from '../../../../app/src/api/studies';
import { useImageViewer } from '../../contextProviders';
import moment from 'moment';
import { capitalize } from '../../../../utils/stringUtils';
import { trpc } from '../../../../app/trpc';

const classes = {
  infoHeader: 'text-base text-primary-light',
  infoText: 'text-base text-white max-w-30',
  firstRow: 'flex flex-col',
  row: 'flex flex-col ml-4',
};

function PatientInfo({
  patientSex,
  patientAge,
  thickness,
  thicknessUnits,
  spacing,
  scanner,
  isOpen,
  showPatientInfoRef,
}) {
  const { t, i18n } = useTranslation('PatientInfo');
  const { data: databaseStudy } = useGetStudy();

  let patientAnamesis = databaseStudy?.anamesis[0];

  if (!patientAnamesis) {
    patientAnamesis = databaseStudy?.patients?.anamesis[0];
  }

  const { data: erdbDiseaseHistory, isLoading } = trpc.patient.getErdb.useQuery({
     patientId: databaseStudy?.patients?.id as number
  }, {enabled: !!databaseStudy?.patients?.id});

  const erdbDiseaseHistoryGroup = useMemo(() => {
    if (
      !erdbDiseaseHistory?.diagnoses_history ||
      erdbDiseaseHistory.diagnoses_history.length === 0
    )
      return [];

    return (
      Object.values(
        erdbDiseaseHistory.diagnoses_history.reduce((result, item) => {
          (result[item.icd10] = result[item.icd10] || []).push(item);
          return result;
        }, {})
      ) as typeof erdbDiseaseHistory.diagnoses_history[]
    )
      .map(innerArray =>
        [...innerArray].sort((a, b) =>
          (a.beginDate || '').localeCompare(b.beginDate || '')
        )
      )
      .sort((a, b) =>
        (a[0].beginDate || '').localeCompare(b[0].beginDate || '')
      );
  }, [erdbDiseaseHistory]);

  while (patientAge.charAt(0) === '0') {
    patientAge = patientAge.substr(1);
  }
  return (
    <div ref={showPatientInfoRef}>
      <Tooltip
        isSticky
        isDisabled={!isOpen}
        position="bottom-right"
        contentClassName="fixed sm:relative w-full sm:w-max-content top-[auto] left-0"
        content={
          isOpen && (
            <div
              className="whitespace-normal flex flex-col select-text py-2 sm:max-w-[466px] sm:w-[76vw] lg:w-[30] xl:w-[27vw] max-h-[65vh] ohif-scrollbar overflow-y-auto"
              onClick={e => e.stopPropagation()}
            >
              <span
                className="text-base font-bold text-white"
                title={databaseStudy?.patients?.fullname || ''}
              >
                {databaseStudy?.patients?.fullname || ''}
              </span>
              <div className="flex pb-4 mt-4 mb-4 border-b border-secondary-main">
                <div className={classnames(classes.firstRow)}>
                  <span className={classnames(classes.infoHeader)}>
                    {t('Sex')}
                  </span>
                  <span
                    className={classnames(classes.infoText)}
                    title={patientSex}
                  >
                    {patientSex}
                  </span>
                </div>
                <div className={classnames(classes.row)}>
                  <span className={classnames(classes.infoHeader)}>
                    {t('Age')}
                  </span>
                  <span
                    className={classnames(classes.infoText)}
                    title={patientAge}
                  >
                    {patientAge}
                  </span>
                </div>
                <div className={classnames(classes.row)}>
                  <span className={classnames(classes.infoHeader)}>
                    {t('MRN')}
                  </span>
                  <span
                    className={classnames(classes.infoText)}
                    title={databaseStudy?.patients?.iin || ''}
                  >
                    {databaseStudy?.patients?.iin || ''}
                  </span>
                </div>
              </div>
              <div className="flex mb-5">
                <div className={classnames(classes.firstRow)}>
                  <span className={classnames(classes.infoHeader)}>
                    {t('Thickness')}
                  </span>
                  <span
                    className={classnames(classes.infoText)}
                    title={thickness}
                  >
                    {thicknessUnits ? `${thickness}${thicknessUnits}` : `${thickness}`}
                  </span>
                </div>
                <div className={classnames(classes.row)}>
                  <span className={classnames(classes.infoHeader)}>
                    {t('Spacing')}
                  </span>
                  <span
                    className={classnames(classes.infoText)}
                    title={spacing}
                  >
                    {spacing}
                  </span>
                </div>
                <div className={classnames(classes.row)}>
                  <span className={classnames(classes.infoHeader)}>
                    {t('Scanner')}
                  </span>
                  <span
                    className={classnames(classes.infoText)}
                    title={scanner}
                  >
                    {scanner}
                  </span>
                </div>
              </div>
              <Accordion
                isOpen={true}
                className="p-1 rounded-md mr-2"
                activeClassName="bg-secondary-dark"
                startIcon={
                  <Icon name="chevron-next" className="text-primary-light" />
                }
                title={
                  <span className="text-primary-light text-lg">
                    {t('Anamnesis')}
                  </span>
                }
              >
                <div className="pl-4 mt-3">
                  {[
                    {
                      header: t('Complaints'),
                      info: patientAnamesis?.complaints,
                      title: patientAnamesis?.complaints,
                    },
                    {
                      header: t('Medical history'),
                      info: patientAnamesis?.medical_history,
                      title: patientAnamesis?.medical_history,
                    },
                    {
                      header: t('Postponed operations'),
                      info: patientAnamesis?.postponed_operations,
                      title: patientAnamesis?.postponed_operations,
                    },
                    {
                      header: t('Metal/foreign bodies'),
                      info: patientAnamesis?.metalforeign_bodies,
                      title: patientAnamesis?.metalforeign_bodies,
                    },
                    {
                      header: t('Electronic devices'),
                      info: patientAnamesis?.electronic_devices,
                      title: patientAnamesis?.electronic_devices,
                    },
                  ].map(({ header, info, title }) => (
                    <div className="flex mb-5" key={header}>
                      <div className={classnames(classes.firstRow)}>
                        <span className={classnames(classes.infoHeader)}>
                          {header}:
                        </span>
                        <span
                          className={classnames(classes.infoText)}
                          title={title || ''}
                        >
                          {info || '-'}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </Accordion>

              <Accordion
                className="mt-4 p-1 rounded-md mr-2"
                activeClassName="bg-secondary-dark"
                startIcon={
                  <Icon name="chevron-next" className="text-primary-light" />
                }
                title={
                  <span className="text-primary-light text-lg">
                    {t('History of diagnoses with ERDB')}
                  </span>
                }
              >
                {isLoading && (
                  <div className="flex justify-center py-4">
                    <Icon name="circle-notch" className="icon-pulse w-6 h-6" />
                  </div>
                )}

                {!isLoading &&
                  erdbDiseaseHistoryGroup.map(erdbDiseaseHistory => {
                    return (
                      <Accordion
                        className={`pl-4 mt-3 p-1 rounded-md mr-2 ${
                          erdbDiseaseHistory.some(erh => !erh.endDate)
                            ? ''
                            : 'text-common-dark'
                        }`}
                        activeClassName="bg-secondary-dark"
                        startIcon={<Icon name="chevron-next" />}
                        title={
                          <div className="flex items-center">
                            <p>
                              <Tooltip
                                position="bottom-left"
                                content={t('Diagnosis code')}
                              >
                                {erdbDiseaseHistory[0].icd10}
                              </Tooltip>
                            </p>
                            <p className="ml-3">
                              <Tooltip
                                position="bottom-left"
                                content={t('Diagnosis name')}
                              >
                                {
                                  erdbDiseaseHistory[0][
                                    `diagName${capitalize(
                                      i18n.language
                                    ).substring(0, 2)}`
                                  ]
                                }
                              </Tooltip>
                            </p>
                          </div>
                        }
                      >
                        <div className="relative ml-1 sm:ml-4 mt-2 cursor-default">
                          <div className="border-r border-white w-[1px] absolute left-[calc((33%+56px)/2)] sm:left-[calc((25%+70px)/2)] h-full"></div>

                          {erdbDiseaseHistory.map(erdbDisease => {
                            return (
                              <div className="flex mb-3">
                                <div className="w-1/3 sm:w-1/4">
                                  <div className="relative">
                                    <p>
                                      <Tooltip
                                        position="bottom-left"
                                        content={t('Production date')}
                                      >
                                        <p className="text-xs sm:text-[13px] pr-2">
                                          {erdbDisease.beginDate
                                            ? moment(
                                                erdbDisease.beginDate
                                              ).format('DD.MM.YYYY')
                                            : ''}
                                        </p>
                                      </Tooltip>
                                    </p>
                                    <p>
                                      <Tooltip
                                        position="bottom-left"
                                        content={t('Date of taking')}
                                      >
                                        <p className="text-xs sm:text-[13px] pr-2">
                                          {erdbDisease.endDate
                                            ? moment(
                                                erdbDisease.endDate
                                              ).format('DD.MM.YYYY')
                                            : ''}
                                        </p>
                                      </Tooltip>
                                    </p>

                                    <div className="w-2 h-2 rounded-full bg-white absolute top-1/2 left-[calc((100%+56px)/2)] sm:left-[calc((100%+70px)/2)] -translate-y-1/2 -translate-x-1/2"></div>
                                  </div>
                                </div>

                                <div className="w-2/3 sm:w-3/4">
                                  <p>
                                    <Tooltip
                                      position="bottom-left"
                                      content={t("Doctor's full name")}
                                    >
                                      <p className="text-[11px] sm:text-[13px] pl-2">
                                        {erdbDisease.doctorFullName || ''}
                                      </p>
                                    </Tooltip>
                                  </p>
                                  <p>
                                    <Tooltip
                                      position="bottom-left"
                                      content={t('Registration type')}
                                    >
                                      <p className="text-[11px] sm:text-[13px] pl-2">
                                        {
                                          erdbDisease[
                                            `regType${capitalize(
                                              i18n.language
                                            ).substring(0, 2)}`
                                          ]
                                        }
                                      </p>
                                    </Tooltip>
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Accordion>
                    );
                  })}
              </Accordion>
            </div>
          )
        }
      >
        <Icon
          className="cursor-pointer text-white hover:text-primary-light"
          name="info-action"
        />
      </Tooltip>
    </div>
  );
}

PatientInfo.propTypes = {
  patientSex: PropTypes.string,
  patientAge: PropTypes.string,
  thickness: PropTypes.string,
  thicknessUnits: PropTypes.string,
  spacing: PropTypes.string,
  scanner: PropTypes.string,
  isOpen: PropTypes.bool,
  showPatientInfoRef: PropTypes.object,
};

export default PatientInfo;
