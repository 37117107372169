import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import qs from 'query-string';
import isEqual from 'lodash.isequal';
import { useTranslation } from 'react-i18next';
//
import filtersMeta from './filtersMeta.js';
import { useAppConfig } from '@state';
import { useDebounce, useSearchParams } from '@hooks';
import { utils, hotkeys, ServicesManager } from '@ohif/core';
// import Tooltip from 'rc-tooltip';
import 'tippy.js/dist/tippy.css';

import {
  Icon,
  StudyListExpandedRow,
  LegacyButton,
  Button,
  EmptyStudies,
  StudyListTable,
  StudyListPagination,
  StudyListFilter,
  TooltipClipboard,
  Header,
  useModal,
  AboutModal,
  UserPreferences,
  LoadingIndicatorProgress,
} from '@ohif/ui';

import i18n from '@ohif/i18n';
import {
  EditStudyIcon,
  AppointmentsIcon,
  SmsStatusIcons,
  DownloadConclusionIcon,
  CancelStudyIcon,
  CloudStatus,
  ServicesIcon,
  RewriteResponsibleUserBtn,
} from './AppointmentsIcon';
import { Studies } from '../../types/StudyTypes';
import PanelConclusion from '../../../../../extensions/default/src/Panels/PanelConclusionNew';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import UserService from '../../userService';
import { trpc } from '../../../trpc';
import { formatDate } from '../../../../utils/dateUtils';

const { sortBySeriesDate } = utils;

const { availableLanguages, defaultLanguage, currentLanguage } = i18n;

const seriesInStudiesMap = new Map();

/**
 * TODO:
 * - debounce `setFilterValues` (150ms?)
 */
function WorkList({
  data: studies,
  dataTotal: studiesTotal,
  isLoadingData,
  dataSource,
  hotkeysManager,
  dataPath,
  onRefresh,
  servicesManager,
  onRerenderList,
  isFetching,
}) {
  const { UIModalService, UINotificationService } = servicesManager.services;
  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const { show, hide } = useModal();
  const { t } = useTranslation();
  // ~ Modes
  const [appConfig] = useAppConfig();
  // ~ Filters
  const searchParams = useSearchParams();
  const navigate = useNavigate();
  const STUDIES_LIMIT = 101;
  const queryFilterValues = _getQueryFilterValues(searchParams);

  const user = trpc.general.getUser.useQuery();

  const studyStatusOptions = [
    {
      value: 'IN_PROGRESS',
      label: 'Ожидает обработки',
    },
    {
      value: 'FINISHED',
      label: 'Завершен',
    },
    {
      value: 'CREATED',
      label: 'Создан',
    },
    {
      value: 'CANCELLED',
      label: 'Отменен',
    },
  ];

  const availableModalities = trpc.general.getModalities.useQuery({
    orgIds: user.data?.organization_user.map(ou => ou.organization_id!),
  });

  const modalities = useMemo(() => {
    if (!availableModalities.data) return [];
    return availableModalities.data.map(m => {
      return { value: m.name, label: m.name };
    });
  }, [availableModalities.data]);

  const { data: responsibleUsers } = trpc.general.getUsers.useQuery({
    orgIds: user.data?.organization_user.map(ou => ou.organization_id!),
    isResponsible: true,
  });

  const responsibleUsersOptions = useMemo(() => {
    if (!responsibleUsers) return [];

    return responsibleUsers.map(u => {
      return { value: String(u.id), label: u.fullname };
    });
  }, [responsibleUsers]);

  const { data: services = [] } = trpc.general.getServices.useQuery({
    orgIds: user.data?.organization_user.map(ou => ou.organization_id!),
  });

  const servicesOptions = useMemo(() => {
    if (!services) return [];

    return services.map(u => {
      return { value: u.service_code, label: u.service_name };
    });
  }, [services]);

  const { data: paymentTypes = [] } = trpc.general.getPaymentTypes.useQuery();

  const paymentTypesOptions = useMemo(() => {
    if (!paymentTypes) return [];

    return paymentTypes.map(u => {
      return { value: String(u.id), label: u.payment_type_rus };
    });
  }, [paymentTypes]);

  const defaultFilterValues = {
    patientName: '',
    mrn: '',
    studyDate: {
      startDate: null,
      endDate: null,
    },
    description: '',
    modalities: [],
    accession: [],
    responsibleDoctor: [],
    paymentType: [],
    sortBy: '',
    sortDirection: 'none',
    pageNumber: 1,
    resultsPerPage: 25,
    datasourcename: '',
    status: [],
    extraStatus: {
      incorrectIIN: null,
      incorrectPhone: null,
      waitsSecondReader: null,
      assignedToMe: null,
      patientComments: null,
    },
  };
  const [filterValues, _setFilterValues] = useState({
    ...defaultFilterValues,
    ...queryFilterValues,
    status: [
      ...['FINISHED', 'IN_PROGRESS', 'CREATED'],
      searchParams.get('status')?.includes('CANCELLED') ? 'CANCELLED' : '',
    ],
  });

  const debouncedFilterValues = useDebounce(filterValues, 200);
  const { resultsPerPage, pageNumber, sortBy, sortDirection } = filterValues;

  const filtersMeta = [
    {
      name: 'mrn',
      displayName: 'IIN',
      inputType: 'Text',
      isSortable: true,
      gridCol: 2,
      className: 'w-full lg:w-12/24 lg:w-4/24 xl:w-2/24',
    },
    {
      name: 'patientName',
      displayName: 'PatientName',
      inputType: 'Text',
      isSortable: true,
      gridCol: 3,
      className: 'w-3/24 hidden xl:block',
    },
    {
      name: 'studyDate',
      displayName: 'StudyDate',
      inputType: 'DateRange',
      isSortable: true,
      gridCol: 3,
      className: 'w-full lg:w-3/24',
    },
    {
      name: 'description',
      displayName: 'Description',
      inputType: 'Text',
      isSortable: true,
      gridCol: 2,
      className: 'w-2/24 hidden xl:block',
    },
    {
      name: 'modalities',
      displayName: 'Type',
      inputType: 'MultiSelect',
      inputProps: {
        options: modalities,
      },
      isSortable: true,
      gridCol: 1,
      className: 'w-full lg:w-1/24',
    },
    {
      name: 'accession',
      displayName: 'ServiceName',
      inputType: 'MultiSelect',
      isSortable: true,
      inputProps: {
        options: servicesOptions,
      },
      gridCol: 3,
      className: 'w-4/24 xl:w-3/24 hidden lg:block',
    },
    {
      name: 'responsibleDoctor',
      displayName: 'Врач-исполнитель',
      inputType: 'MultiSelect',
      inputProps: {
        options: responsibleUsersOptions,
      },
      isSortable: true,
      gridCol: 3,
      className: 'w-full lg:w-4/24 xl:w-3/24',
    },
    {
      name: 'paymentType',
      displayName: 'Оплата',
      inputType: 'MultiSelect',
      inputProps: {
        options: paymentTypesOptions,
      },
      isSortable: true,
      gridCol: 2,
      className: 'w-3/24 xl:w-2/24 hidden xl:block',
    },
    {
      name: 'status',
      displayName: 'Status',
      inputType: 'MultiSelect',
      inputProps: {
        options: studyStatusOptions,
      },
      isSortable: true,
      gridCol: 2,
      className: 'lg:w-3/24 xl:w-2/24 hidden lg:block',
    },
    {
      name: 'extraStatus',
      displayName: '',
      inputType: 'checkboxGroup',
      inputProps: {
        options: [
          { name: 'incorrectIIN', label: 'Некорректный иин' },
          { name: 'incorrectPhone', label: 'Некорректный телефон' },
          {
            name: 'assignedToMe',
            label: 'Назначены мне',
            hidden: !user.data?.canMakeConclusions,
          },
          {
            name: 'waitsSecondReader',
            label: 'Ожидают второго чтения',
            hidden: !user.data?.roleHelper.isFirstReader && !user.data?.roleHelper.isSecondReader,
          },
          {
            name: 'patientComments',
            label: 'Военкомат',
          },
        ],
      },
      isSortable: false,
      gridCol: 3,
      className: 'w-6/24 hidden lg:block lg:w-5/24 xl:w-3/24',
    },
  ];

  const shouldUseDefaultSort = sortBy === '' || !sortBy;
  const sortModifier = sortDirection === 'descending' ? 1 : -1;
  const defaultSortValues = shouldUseDefaultSort
    ? { sortBy: 'studyDate', sortDirection: 'descending' }
    : {};
  const sortedStudies = studies;

  // ~ Rows & Studies
  const [expandedRows, setExpandedRows] = useState([]);
  const [studiesWithSeriesData, setStudiesWithSeriesData] = useState([]);
  const numOfStudies = studiesTotal;
  const querying = useMemo(() => {
    return isLoadingData || expandedRows.length > 0;
  }, [isLoadingData, expandedRows]);

  const setFilterValues = val => {
    if (filterValues.pageNumber === val.pageNumber) {
      val.pageNumber = 1;
    }

    _setFilterValues(val);
    setExpandedRows([]);
  };

  const onPageNumberChange = newPageNumber => {
    setFilterValues({ ...filterValues, pageNumber: newPageNumber });
  };

  const onResultsPerPageChange = newResultsPerPage => {
    setFilterValues({
      ...filterValues,
      pageNumber: 1,
      resultsPerPage: Number(newResultsPerPage),
    });
  };

  // Set body style
  useEffect(() => {
    document.body.classList.add('bg-black');
    return () => {
      document.body.classList.remove('bg-black');
    };
  }, []);

  // Sync URL query parameters with filters
  useEffect(() => {
    if (!debouncedFilterValues) {
      return;
    }

    const queryString = {};
    Object.keys(defaultFilterValues).forEach(key => {
      const defaultValue = defaultFilterValues[key];
      const currValue = debouncedFilterValues[key];

      // TODO: nesting/recursion?
      if (key === 'extraStatus') {
        queryString.incorrectPhone = currValue.incorrectPhone || '';
        queryString.incorrectIIN = currValue.incorrectIIN || '';
        queryString.assignedToMe = currValue.assignedToMe || '';
        queryString.waitsSecondReader = currValue.waitsSecondReader || '';
        queryString.patientComments = currValue.patientComments || '';
      } else if (key === 'studyDate') {
        if (currValue.startDate && defaultValue.startDate !== currValue.startDate) {
          queryString.startDate = currValue.startDate;
        }
        if (currValue.endDate && defaultValue.endDate !== currValue.endDate) {
          queryString.endDate = currValue.endDate;
        }
      } else if (key === 'modalities' && currValue.length) {
        queryString.modalities = currValue.join(',');
      } else if (key === 'responsibleDoctor' && currValue.length) {
        queryString.responsibleDoctor = currValue.join(',');
      } else if (key === 'accession' && currValue.length) {
        queryString.accession = currValue.join(',');
      } else if (key === 'paymentType' && currValue.length) {
        queryString.paymentType = currValue.join(',');
      } else if (key === 'status' && currValue.length) {
        queryString.status = currValue.join(',');
      } else if (currValue !== defaultValue) {
        queryString[key] = currValue;
      }
    });

    const search = qs.stringify(queryString, {
      skipNull: true,
      skipEmptyString: true,
    });

    navigate({
      pathname: '/',
      search: search ? `?${search}` : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterValues]);

  // Query for series information
  useEffect(() => {
    const fetchSeries = async studyInstanceUid => {
      try {
        const series = await dataSource.query.series.search(studyInstanceUid);
        seriesInStudiesMap.set(studyInstanceUid, sortBySeriesDate(series));
        setStudiesWithSeriesData([...studiesWithSeriesData, studyInstanceUid]);
      } catch (ex) {
        // TODO: UI Notification Service
        console.warn(ex);
      }
    };

    // TODO: WHY WOULD YOU USE AN INDEX OF 1?!
    // Note: expanded rows index begins at 1
    for (let z = 0; z < expandedRows.length; z++) {
      const expandedRowIndex = expandedRows[z] - 1;
      const studyInstanceUid = sortedStudies[expandedRowIndex].ohif_id;

      if (studiesWithSeriesData.includes(studyInstanceUid)) {
        continue;
      }

      fetchSeries(studyInstanceUid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRows, studies]);

  const isFiltering = (filterValues, defaultFilterValues) => {
    return !isEqual(filterValues, defaultFilterValues);
  };

  const tableDataSource = sortedStudies.map((study: Studies, key) => {
    const rowKey = key + 1;
    const isExpanded = expandedRows.some(k => k === rowKey);
    const {
      id,
      ohif_id,
      modalities,
      description,
      patients,
      date,
      time,
      status,
      responsible_user,
      first_reader_responsible_user,
      payment_type,
      pre_conclusion,
      conclusion,
      is_mammo,
    } = study;

    const studyDate = formatDate(date, 'DD.MM.YYYY', ['YYYYMMDD', 'YYYY.MM.DD'])
    // const studyTime = formatDate(time, 'HH:mm', ['HHmmss.SSS', 'HHmmss', 'HHmm', 'HH']);

    return {
      row: [
        {
          key: 'mrn',
          content: (
            <div className="flex items-center gap-2">
              <div className="block shrink-0 grow-0 basis-6 lg:hidden">
                <img
                  className="h-full w-full"
                  src={
                    status === 'FINISHED'
                      ? '/assets/app/paper-green.png'
                      : status === 'IN_PROGRESS'
                      ? '/assets/app/clock-orange.png'
                      : status === 'CREATED'
                      ? '/assets/app/pencil-blue.png'
                      : status === 'CANCELLED'
                      ? '/assets/app/cancel.png'
                      : ''
                  }
                  alt=""
                />
              </div>

              <div className="flex flex-col">
                <p className="inline-block whitespace-normal xl:hidden">{patients?.fullname}</p>
                <p className="text-common-light xl:text-white">
                  <TooltipClipboard>{patients?.iin || ''}</TooltipClipboard>
                </p>
              </div>
            </div>
          ),
          gridCol: 2,
          className: 'w-12/24 lg:w-4/24 xl:w-2/24',
        },
        {
          key: 'patientName',
          content: patients?.fullname ? (
            <TooltipClipboard>{patients?.fullname}</TooltipClipboard>
          ) : (
            <span className="text-gray-700">(Empty)</span>
          ),
          gridCol: 3,
          className: 'w-3/24 hidden xl:table-cell',
        },
        {
          key: 'middleInfo',
          content: (
            <div className="flex flex-col gap-1">
              <p className="font-bold">{modalities}</p>
              <p className="whitespace-normal">{description}</p>
              <p>{studyDate && <span className="text-common-light mr-4">{studyDate}</span>}</p>
            </div>
          ),
          gridCol: 3,
          className: 'table-cell lg:hidden w-6/24',
        },
        {
          key: 'studyDate',
          content: (
            <>
              {studyDate && <span className="mr-4">{studyDate}</span>}
              {/* {studyTime && <span>{studyTime}</span>} */}
            </>
          ),
          title: `${studyDate || ''}`,
          gridCol: 3,
          className: 'w-3/24 hidden lg:table-cell',
        },
        {
          key: 'description',
          content: <TooltipClipboard>{description || ''}</TooltipClipboard>,
          gridCol: 2,
          className: 'w-2/24 hidden xl:table-cell',
        },
        {
          key: 'modality',
          content: modalities,
          title: modalities,
          gridCol: 1,
          className: 'w-1/24 hidden lg:table-cell',
        },
        {
          key: 'accession',
          content: <ServicesIcon study={study} />,
          gridCol: 3,
          className: 'w-4/24 xl:w-3/24 hidden lg:table-cell',
        },
        {
          key: 'responsibleDoctor',
          content: <RewriteResponsibleUserBtn study={study} />,
          gridCol: 3,
          className: 'w-4/24 xl:w-3/24 hidden lg:table-cell',
        },
        {
          key: 'paymentType',
          content: <TooltipClipboard>{payment_type?.payment_type_rus || ''}</TooltipClipboard>,
          gridCol: 2,
          className: 'w-3/24 xl:w-2/24 hidden xl:table-cell',
        },
        {
          key: 'status',
          content: (
            <div>
              <span>
                {status === 'FINISHED'
                  ? 'Завершен'
                  : status === 'IN_PROGRESS'
                  ? 'Ожидает обработки'
                  : status === 'CANCELLED'
                  ? 'Отменен'
                  : status === 'CREATED'
                  ? 'Создан'
                  : ''}
              </span>

              {is_mammo && (
                <div className="flex gap-1">
                  <Button
                    size="small"
                    formType="button"
                    endIcon={
                      <Icon
                        name={pre_conclusion[0] ? 'notifications-success' : 'icon-clear-field'}
                      />
                    }
                  >
                    1
                  </Button>

                  <Button
                    size="small"
                    formType="button"
                    endIcon={
                      <Icon name={conclusion[0] ? 'notifications-success' : 'icon-clear-field'} />
                    }
                  >
                    2
                  </Button>
                </div>
              )}
            </div>
          ),
          title: '',
          gridCol: 2,
          className: 'lg:w-3/25 xl:w-2/24 hidden lg:table-cell',
        },
        {
          key: 'extraStatus',
          content: (
            <div
              className="flex flex-wrap justify-start gap-2 pr-4 sm:pr-0"
              onClick={e => e.stopPropagation()}
            >
              <CloudStatus study={study} />
              <EditStudyIcon study={study} />
              <SmsStatusIcons study={study} />
              <AppointmentsIcon study={study} />
              {(study.uploaded_to_the_cloud || study.conclusion.length > 0) && (
                <DownloadConclusionIcon study={study} />
              )}
              {(status === 'IN_PROGRESS' || status === 'CANCELLED') && (
                <CancelStudyIcon study={study} />
              )}
            </div>
          ),
          title: '',
          gridCol: 3,
          className: 'w-6/24 lg:w-5/24 xl:w-3/24',
        },
      ],
      // Todo: This is actually running for all rows, even if they are
      // not clicked on.
      expandedContent: (
        <StudyListExpandedRow
          seriesTableColumns={{
            description: 'Description',
            seriesNumber: 'Series',
            modality: 'Modality',
            instances: 'Instances',
          }}
          seriesTableDataSource={
            seriesInStudiesMap.has(ohif_id)
              ? seriesInStudiesMap.get(ohif_id).map(s => {
                  return {
                    description: s.description || '(empty)',
                    seriesNumber: s.seriesNumber ?? '',
                    modality: s.modality || '',
                    instances: s.numSeriesInstances || '',
                  };
                })
              : []
          }
        >
          <div className="flex flex-row flex-wrap gap-2">
            {appConfig.loadedModes.map((mode, i) => {
              const modalitiesToCheck = modalities.replaceAll('/', '\\');

              const isValidMode = mode.isValidMode({
                modalities: modalitiesToCheck,
                study,
              });
              return (
                <React.Fragment key={mode.routeName}>
                  <ViewerLink
                    displayName={mode.displayName}
                    isValidMode={isValidMode}
                    link={`${mode.routeName}?StudyInstanceUIDs=${ohif_id}`}
                    study={study}
                    onSave={onRerenderList}
                  />
                </React.Fragment>
              );
            })}

            <ViewerLink
              displayName={'Volume Rendering'}
              isValidMode={true}
              link={`viewer?StudyInstanceUIDs=${ohif_id}&hangingprotocolId=mprAnd3DVolumeViewport`}
              study={study}
              onSave={onRerenderList}
            />
          </div>
        </StudyListExpandedRow>
      ),
      onClickRow: () =>
        setExpandedRows(s => (isExpanded ? s.filter(n => rowKey !== n) : [...s, rowKey])),
      isExpanded,
    };
  });

  const hasStudies = numOfStudies > 0;
  const versionNumber = process.env.VERSION_NUMBER;
  const commitHash = process.env.COMMIT_HASH;

  const menuOptions = [
    {
      title: 'отчеты',
      icon: 'report-page',
      onClick: () => navigate('/report'),
    },
    {
      title: t('Header:Preferences'),
      icon: 'settings',
      onClick: () =>
        show({
          title: t('UserPreferencesModal:User Preferences'),
          content: UserPreferences,
          contentProps: {
            hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(hotkeyDefaults),
            hotkeyDefinitions,
            onCancel: hide,
            currentLanguage: currentLanguage(),
            availableLanguages,
            defaultLanguage,
            onSubmit: state => {
              if (state.language.value !== currentLanguage().value) {
                i18n.changeLanguage(state.language.value);
              }
              hotkeysManager.setHotkeys(state.hotkeyDefinitions);
              hide();
            },
            onReset: () => hotkeysManager.restoreDefaultBindings(),
            hotkeysModule: hotkeys,
          },
        }),
    },
  ];

  menuOptions.push({
    icon: 'power-off',
    title: t('Header:Logout'),
    onClick: () => {
      UserService.doLogout();
    },
  });

  const { customizationService } = servicesManager.services;
  const { component: dicomUploadComponent } =
    customizationService.get('dicomUploadComponent') ?? {};
  const uploadProps =
    dicomUploadComponent && dataSource.getConfig()?.dicomUploadEnabled
      ? {
          title: 'Upload files',
          closeButton: true,
          shouldCloseOnEsc: false,
          shouldCloseOnOverlayClick: false,
          content: dicomUploadComponent.bind(null, {
            dataSource,
            onComplete: () => {
              hide();
              onRefresh();
            },
            onStarted: () => {
              show({
                ...uploadProps,
                // when upload starts, hide the default close button as closing the dialogue must be handled by the upload dialogue itself
                closeButton: false,
              });
            },
          }),
        }
      : undefined;

  const { component: dataSourceConfigurationComponent } =
    customizationService.get('ohif.dataSourceConfigurationComponent') ?? {};

  return (
    <div
      className={classnames('h-full bg-black', {
        'h-screen': !hasStudies,
      })}
    >
      <Header
        isSticky={false}
        menuOptions={menuOptions}
        isReturnEnabled={false}
        WhiteLabeling={appConfig.whiteLabeling}
      />
      <StudyListFilter
        numOfStudies={numOfStudies}
        filtersMeta={filtersMeta}
        filterValues={{ ...filterValues, ...defaultSortValues }}
        onChange={setFilterValues}
        clearFilters={() => setFilterValues(defaultFilterValues)}
        isFiltering={isFiltering(filterValues, defaultFilterValues)}
        onUploadClick={uploadProps ? () => show(uploadProps) : undefined}
        getDataSourceConfigurationComponent={
          dataSourceConfigurationComponent ? () => dataSourceConfigurationComponent() : undefined
        }
      />

      {!isFetching && hasStudies && (
        <>
          <StudyListTable
            tableDataSource={tableDataSource}
            querying={querying}
            filtersMeta={filtersMeta}
          />
          <StudyListPagination
            onChangePage={onPageNumberChange}
            onChangePerPage={onResultsPerPageChange}
            currentPage={pageNumber}
            perPage={resultsPerPage}
            numOfStudies={numOfStudies}
          />
        </>
      )}

      {isFetching && (
        <div className="relative h-44">
          <LoadingIndicatorProgress className={'h-full w-full bg-black'} />
        </div>
      )}
    </div>
  );
}

WorkList.propTypes = {
  data: PropTypes.array.isRequired,
  dataSource: PropTypes.shape({
    query: PropTypes.object.isRequired,
    getConfig: PropTypes.func,
  }).isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  servicesManager: PropTypes.instanceOf(ServicesManager),
};

function _tryParseInt(str, defaultValue) {
  let retValue = defaultValue;
  if (str && str.length > 0) {
    if (!isNaN(str)) {
      retValue = parseInt(str);
    }
  }
  return retValue;
}

function _getQueryFilterValues(params) {
  const queryFilterValues = {
    patientName: params.get('patientName'),
    mrn: params.get('mrn'),
    studyDate: {
      startDate: params.get('startDate'),
      endDate: params.get('endDate'),
    },
    description: params.get('description'),
    modalities: params.get('modalities') ? params.get('modalities').split(',') : [],
    accession: params.get('accession') ? params.get('accession').split(',') : [],
    paymentType: params.get('paymentType') ? params.get('paymentType').split(',') : [],
    responsibleDoctor: params.get('responsibleDoctor')
      ? params.get('responsibleDoctor').split(',')
      : [],
    status: params.get('status') ? params.get('status').split(',') : [],
    sortBy: params.get('sortBy'),
    sortDirection: params.get('sortDirection'),
    pageNumber: _tryParseInt(params.get('pageNumber'), undefined),
    resultsPerPage: _tryParseInt(params.get('resultsPerPage'), undefined),
    datasourcename: params.get('datasourcename'),
    extraStatus: {
      incorrectIIN: params.get('incorrectIIN'),
      incorrectPhone: params.get('incorrectPhone'),
      waitsSecondReader: params.get('waitsSecondReader'),
      assignedToMe: params.get('assignedToMe'),
      patientComments: params.get('patientComments'),
    },
  };

  // Delete null/undefined keys
  Object.keys(queryFilterValues).forEach(
    key => queryFilterValues[key] == null && delete queryFilterValues[key]
  );

  return queryFilterValues;
}

function _sortStringDates(s1, s2, sortModifier) {
  // TODO: Delimiters are non-standard. Should we support them?
  const s1Date = moment(s1.date, ['YYYYMMDD', 'YYYY.MM.DD'], true);
  const s2Date = moment(s2.date, ['YYYYMMDD', 'YYYY.MM.DD'], true);

  if (s1Date.isValid() && s2Date.isValid()) {
    return (s1Date.toISOString() > s2Date.toISOString() ? 1 : -1) * sortModifier;
  } else if (s1Date.isValid()) {
    return sortModifier;
  } else if (s2Date.isValid()) {
    return -1 * sortModifier;
  }
}

const ViewerLink = ({
  link,
  displayName,
  isValidMode,
  study,
  onSave,
}: {
  link: string;
  displayName: string;
  isValidMode: boolean;
  study: Studies;
}) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);

  if (!study.ohif_id && !study.is_mammo) {
    return (
      <>
        <LegacyButton
          rounded="full"
          variant={isValidMode ? 'contained' : 'disabled'}
          disabled={!isValidMode}
          endIcon={<Icon name="launch-arrow" />}
          className={classnames('mr-2 mb-2 whitespace-nowrap rounded-sm font-medium')}
          onClick={open}
        >
          {t(`Modes:${displayName}`)}
        </LegacyButton>

        <Modal
          opened={opened}
          onClose={close}
          size={1050}
        >
          <PanelConclusion
            studyId={study.id}
            onSave={onSave}
          />
        </Modal>
      </>
    );
  }

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <LegacyButton
        rounded="full"
        variant={isValidMode ? 'contained' : 'disabled'}
        disabled={!isValidMode}
        endIcon={<Icon name="launch-arrow" />}
        className={classnames('mr-2 mb-2 whitespace-nowrap rounded-sm font-medium')}
      >
        {t(`Modes:${displayName}`)}
      </LegacyButton>
    </a>
  );
};

export default WorkList;
