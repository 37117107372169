import React from 'react';
import { Table, Checkbox, ActionIcon, Flex, Button } from '@mantine/core';
import NewVisitModal from '../../../../../../platform/components/NewVisitModal';
import { Modal } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { IconArrowUpRight } from '@tabler/icons-react';
import { useFormContext } from 'react-hook-form';
import { DefaultForm } from '../CnDefault';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../../../medreview/server/src';
import { trpc } from '../../../../../../platform/app/trpc';

interface Props {
  study: NonNullable<inferRouterOutputs<AppRouter>['study']['get']>;
  conclusionId?: number;
  onCnSelect: (id: number) => void;
  disabled: boolean;
}

export default function CnServices({ study, conclusionId, onCnSelect, disabled }: Props) {
  const { t } = useTranslation('Custom');
  const [opened, { open, close }] = useDisclosure(false);
  const { register } = useFormContext<DefaultForm>();
  const trpcUtils = trpc.useUtils();

  return (
    <Table
      withBorder
      withColumnBorders
      className="mt-2"
    >
      <thead>
        <tr>
          <th>{t('Service')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {study?.services.map(studyService => (
          <tr key={studyService.id}>
            <td>{studyService.price.services?.service_name}</td>
            <td colSpan={4}>
              <Flex className="justify-center">
                {studyService.conclusion_id && studyService.conclusion_id !== conclusionId ? (
                  <Flex className="items-center gap-1">
                    <span className="text-center">
                      Заключение <br /> сформировано
                    </span>
                    <ActionIcon
                      variant="filled"
                      onClick={() => onCnSelect(studyService.conclusion_id as number)}
                    >
                      <IconArrowUpRight stroke={2} />
                    </ActionIcon>
                  </Flex>
                ) : (
                  <Checkbox
                    disabled={disabled}
                    value={studyService.id}
                    {...register('study_service_ids')}
                  />
                )}
              </Flex>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={2}>
            <Button
              size="xs"
              disabled={disabled}
              onClick={open}
            >
              {t('Add service')}
            </Button>

            <Modal
              opened={opened}
              onClose={close}
              size={1050}
            >
              <NewVisitModal
                study={{ ...study, modalities: '', is_mammo: false }}
                showPartial="services"
                onClose={() => {
                  trpcUtils.study.get.invalidate();
                  close();
                }}
              />
            </Modal>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
