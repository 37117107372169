import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NewVisitFormType, serviceModalities } from '../schema';
import { Radio, Input } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export default function NewVisitModalitySlugsData({ disabled = false }: { disabled?: boolean }) {
  const { register } = useFormContext<NewVisitFormType>();
  const { t } = useTranslation('Custom');

  return (
    <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
      <Input.Label
        className="sm:w-1/3"
        required
      >
        {t('Type of study')}
      </Input.Label>
      <div className="flex flex-wrap gap-4 sm:w-2/3">
        {Object.entries(serviceModalities).map(([serviceName, modality]) => (
          <div key={serviceName}>
            <Radio
              {...register('serviceModality')}
              label={modality.label}
              disabled={disabled}
              value={serviceName}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
