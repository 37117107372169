import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Label from '../Label';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import { checkIinBinSum } from '../../../../utils/checkIinBinSum';

const baseInputClasses =
  'shadow transition duration-300 appearance-none border border-primary-main hover:border-gray-500 focus:border-gray-500 focus:outline-none rounded w-full py-2 px-3 text-sm text-white leading-tight focus:outline-none';

const transparentClasses = {
  true: 'bg-transparent',
  false: 'bg-black',
};

const smallInputClasses = {
  true: 'input-small',
  false: ''
}

const Input = ({
  id,
  label,
  containerClassName = '',
  labelClassName = '',
  className = '',
  transparent = false,
  smallInput = false,
  type = 'text',
  variant = 'input',
  value,
  onChange,
  onFocus,
  autoFocus,
  onKeyPress,
  onKeyDown,
  readOnly,
  disabled,
  errorMessage,
  onCorrectIINTyped,
  onInCorrectIINTyped,
  placeholder,
  ...otherProps
}) => {
  useEffect(() => {
    if (type === 'IIN' && value && value.length >= 12) {
      checkIinBinSum(value)
        ? onCorrectIINTyped && onCorrectIINTyped(value)
        : onInCorrectIINTyped && onInCorrectIINTyped(value);
    }
  }, [value]);

  const maskTypes = {
    'phone-inputmask': '+7 (799) 999 99 99',
    date: '9999-99-99',
  };

  const inputOptions = {
    'data-cy': `input-${id}`,
    className: classnames(
      label && 'mt-2',
      className,
      baseInputClasses,
      transparentClasses[transparent],
      smallInputClasses[smallInput],
      { 'ohif-scrollbar': variant === 'textarea' },
      { 'cursor-not-allowed': disabled },
      { 'opacity-50': disabled },
      errorMessage && 'border-red-500'
    ),
    readOnly: readOnly,
    autoFocus: autoFocus,
    type: type,
  };

  const eventOptions = {
    onChange: onChange,
    onFocus: onFocus,
    onKeyPress: onKeyPress,
    onKeyDown: onKeyDown,
  };

  if (type == 'phone-inputmask' || type == 'date') {
    return (
      <div className={classnames('flex flex-col flex-1', containerClassName)}>
        <InputMask
          mask={maskTypes[type]}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          {...eventOptions}
        >
          {inputProps => (
            <input {...inputProps} {...inputOptions} type="text" />
          )}
        </InputMask>
        <span className="text-red-500 text-xs">{errorMessage}</span>
      </div>
    );
  }

  return (
    <div className={classnames('flex flex-col flex-1', containerClassName)}>
      <Label className={labelClassName} text={label}></Label>

      {variant === 'input' && (
        <input
          {...inputOptions}
          {...eventOptions}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          {...otherProps}
        />
      )}

      {variant === 'textarea' && (
        <textarea
          {...inputOptions}
          {...eventOptions}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          {...otherProps}
        />
      )}

      <span className="text-red-500 text-xs">{errorMessage}</span>
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  transparent: PropTypes.bool,
  smallInput: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  autoFocus: PropTypes.bool,
  readOnly: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onCorrectIINTyped: PropTypes.func,
  onInCorrectIINTyped: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default Input;
