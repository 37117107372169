import React, { useEffect } from 'react';
import { LegacyButton, Icon, Input, useImageViewer } from '@ohif/ui';
import { useGetStudy } from '../app/src/api/studies';
import { servicesManager } from '../app/src/App';
import { CornerstoneServices } from '../../extensions/cornerstone/src/types';
import { useTranslation } from 'react-i18next';

const ShareLink = ({}) => {
  const { t } = useTranslation('Info');
  const { uiNotificationService } =
    servicesManager.services as CornerstoneServices;

  const { data: study, refetch } = useGetStudy();

  useEffect(() => {
    refetchStudy();
  }, []);

  async function refetchStudy() {
    const { data: study } = await refetch();

    if (!study?.share_dicom_archive[0]?.token) {
      uiNotificationService.show({
        title: '',
        message: t(
          'You can share the link only after saving the conclusion and generating a PDF document. If you have saved the conclusion, repeat the action after 1 minute'
        ),
        type: 'warning',
      });
    }
  }

  if (!study) return null;

  const token = study.share_dicom_archive[0]?.token;
  const link = `${process.env.SHARED_SERVER_URL}/viewer?StudyInstanceUIDs=${study.ohif_id}&pacs-token=${token}`;

  if (!token) return null;

  function copySharedLink() {
    navigator.clipboard.writeText(link);

    uiNotificationService.show({
      title: '',
      message: t('Link copied'),
      type: 'success',
    });
  }

  return (
    <div className="flex gap-1 items-center">
      <Input value={link} />

      <LegacyButton
        variant="contained"
        color="light"
        border="light"
        className="p-0"
        onClick={copySharedLink}
      >
        <Icon name="group-layers" width="17" height="17" />
      </LegacyButton>
    </div>
  );
};
export default ShareLink;
