import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Select } from '@mantine/core';
import { CnFirstMammoForm } from '../CnMammo';

export default function CnMammoXRay({ disabled }: { disabled: boolean }) {
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext<CnFirstMammoForm>();

  const mg_quality = watch('mg_quality');

  return (
    <div className="mt-4">
      {fieldBlocks.map(fieldBlock => {
        return (
          <div
            className="mb-3 flex items-center gap-4"
            key={fieldBlock.name}
          >
            <div className="w-1/2">
              <Controller
                name={`${fieldBlock.name}.right`}
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      label={`${fieldBlock.label} R:`}
                      data={fieldBlock.options}
                      error={errors[fieldBlock.name]?.right?.message}
                      readOnly={
                        disabled ||
                        (fieldBlock.name !== 'mg_quality' && mg_quality.right !== 'GOOD')
                      }
                    />
                  );
                }}
              />
            </div>
            <div className="w-1/2">
              <Controller
                name={`${fieldBlock.name}.left`}
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      label={`${fieldBlock.label} L:`}
                      data={fieldBlock.options}
                      error={errors[fieldBlock.name]?.left?.message}
                      readOnly={
                        disabled || (fieldBlock.name !== 'mg_quality' && mg_quality.left !== 'GOOD')
                      }
                    />
                  );
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

const fieldBlocks = [
  {
    name: 'mg_quality' as const,
    label: 'Качество МГ',
    options: [
      {
        value: 'GOOD' as const,
        label: 'Приемлемое качество',
      },
      {
        value: 'BAD' as const,
        label: 'Плохое качество изображения',
      },
      {
        value: 'INCORRECT' as const,
        label: 'Неправильная укладка',
      },
    ],
  },
  {
    name: 'x_ray_density' as const,
    label: 'Рентгенплотность железы (от I до IV)',
    options: [
      {
        value: '1',
        label: 'I',
      },
      {
        value: '2',
        label: 'II',
      },
      {
        value: '3',
        label: 'III',
      },
      {
        value: '4',
        label: 'IV',
      },
    ],
  },
];
