import React from 'react';
import { useGetBiradsLocs } from '../../../../hooks/useGetBiradsLocs';
import { toRoman } from '../../../../utils/numberUtils';
import { Input, Textarea } from '@mantine/core';
import dayjs from 'dayjs';
import { Studies } from 'platform/app/src/types/StudyTypes';
import { useTranslation } from 'react-i18next';
import CkeEditor from '../../../CkeEditor/CkeEditor';

const mapMgQuality = {
  GOOD: 'Приемлемое качество',
  BAD: 'Плохое качество изображения',
  INCORRECT: 'Неправильная укладка',
};

export default function MammoVisitFirstReader({
  study,
  showPartial,
}: {
  study?: Studies;
  showPartial?: 'mammo' | 'services';
}) {
  const { options } = useGetBiradsLocs();
  const resultMammo = study?.pre_conclusion[0]?.result_mammo[0];
  const { t } = useTranslation('Custom');

  const RLValues = [
    {
      name: 'x_ray_density',
      label: t('X-ray density'),
      value: {
        right: toRoman(resultMammo?.x_ray_density?.right),
        left: toRoman(resultMammo?.x_ray_density?.left),
      },
    },
    {
      name: 'mg_quality',
      label: t('MG quality'),
      value: {
        right: resultMammo?.mg_quality ? mapMgQuality[resultMammo?.mg_quality.right] : '',
        left: resultMammo?.mg_quality ? mapMgQuality[resultMammo?.mg_quality.left] : '',
      },
    },
    {
      name: 'birads_code',
      label: t('Result'),
      value: {
        right: resultMammo
          ? options.birads.find(b => b.value === String(resultMammo.birads_code_right))?.label
          : '',
        left: resultMammo
          ? options.birads.find(b => b.value === String(resultMammo.birads_code_left))?.label
          : '',
      },
    },
  ];

  return (
    <>
      <fieldset className="mt-12 border p-4">
        <legend className="px-4 font-bold">{t('First reading results')}</legend>

        <img
          src={
            resultMammo?.mammo_pic
              ? `${process.env.S3_URL}/${resultMammo.mammo_pic}`
              : '/assets/app/mammo.png'
          }
          alt="RL"
          crossOrigin="anonymous"
          className="mb-3"
        />

        <div className="mt-4 flex flex-col sm:flex-row">
          <div className="sm:w-1/3"></div>
          <div className="flex gap-2 sm:w-2/3">
            <p className="w-1/2 text-center">R</p>
            <p className="w-1/2 text-center">L</p>
          </div>
        </div>

        {RLValues.map(RL => {
          return (
            <div
              className="mt-4 flex flex-col sm:flex-row"
              key={RL.name}
            >
              <Input.Label className="sm:w-1/3">{RL.label}</Input.Label>
              <div className="flex gap-2 sm:w-2/3">
                <Input
                  value={RL.value.right || ''}
                  readOnly
                  className="w-1/2"
                />
                <Input
                  value={RL.value.left || ''}
                  readOnly
                  className="w-1/2"
                />
              </div>
            </div>
          );
        })}

        <div className="mt-4 flex flex-col sm:flex-row">
          <Input.Label className="sm:w-1/3">{t('Recommendations')}</Input.Label>
          <div className="sm:w-2/3">
            <Textarea
              value={resultMammo?.recs?.split(' ||| ').join('. ')}
              readOnly
            />
          </div>
        </div>

        {showPartial !== 'mammo' && (
          <div className="mt-4 flex flex-col sm:flex-row">
            <Input.Label className="sm:w-1/3">{t('Conclusion')}</Input.Label>
            <div className="sm:w-2/3">
              {study?.pre_conclusion[0]?.conclusion_text && (
                <CkeEditor
                  disabled
                  data={study?.pre_conclusion[0]?.conclusion_text}
                  onChange={() => {}}
                />
              )}
              {!study?.pre_conclusion[0]?.conclusion_text && '-'}
            </div>
          </div>
        )}

        <div className="mt-4 flex flex-col sm:flex-row">
          <Input.Label className="sm:w-1/3">{t('Note')}</Input.Label>
          <div className="sm:w-2/3">
            <Textarea
              value={resultMammo?.note || ''}
              readOnly
            />
          </div>
        </div>

        <div className="mt-4 flex flex-col sm:flex-row">
          <Input.Label className="sm:w-1/3">{t('Date')}</Input.Label>
          <div className="sm:w-2/3">
            <Input
              value={
                study?.pre_conclusion[0]?.created_at
                  ? dayjs(study?.pre_conclusion[0]?.created_at).format('DD.MM.YYYY')
                  : ''
              }
              readOnly
            />
          </div>
        </div>
      </fieldset>
    </>
  );
}
