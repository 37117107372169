import React, { useState } from "react";

interface AccordionProps {
  title: React.ReactNode;
  children: React.ReactNode;
  isOpen?: boolean;
  className?: string;
  activeClassName?: string;
  startIcon?: React.ReactNode;
}

const Accordion = ({
  title,
  children,
  className = '',
  activeClassName = '',
  isOpen = false,
  startIcon,
}: AccordionProps) => {
  const [show, setShow] = useState(isOpen);

  return (
    <div
      className={`${show ? `block ${activeClassName}` : 'none'} ${className}`}
    >
      <div onClick={() => setShow(!show)}>
        {startIcon && (
          <div className="flex gap-1 items-center cursor-pointer">
            <span className={`${show ? 'rotate-90' : 'rotate-0'}`}>
              {startIcon}
            </span>

            {title}
          </div>
        )}

        {!startIcon && title}
      </div>
      <div>{show && children}</div>
    </div>
  );
};

export default Accordion;
