import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LegacyButton from '../LegacyButton';
import Icon from '../Icon';
import Typography from '../Typography';
import InputGroup from '../InputGroup';
import { useNavigate } from 'react-router';
import { modals } from '@mantine/modals';
import NewVisitModal from '../../../../components/NewVisitModal';
import { trpc } from '../../../../app/trpc';

const StudyListFilter = ({
  filtersMeta,
  filterValues,
  onChange,
  clearFilters,
  isFiltering,
  numOfStudies,
  onUploadClick,
  getDataSourceConfigurationComponent,
}) => {
  const user = trpc.general.getUser.useQuery();
  const navigate = useNavigate();
  const { t } = useTranslation('StudyList');
  const { sortBy, sortDirection } = filterValues;
  const filterSorting = { sortBy, sortDirection };
  const setFilterSorting = sortingValues => {
    onChange({
      ...filterValues,
      ...sortingValues,
    });
  };

  return (
    <React.Fragment>
      <div>
        <div className="bg-primary-dark">
          <div className="relative m-auto flex flex-col pt-5 xl:container">
            <div className="mb-5 flex flex-row justify-start px-4 sm:justify-end lg:px-12 xl:justify-between">
              <div className="hidden flex-row xl:flex">
                <Typography
                  variant="h4"
                  className="text-primary-light mr-6"
                >
                  {t('StudyList')}
                </Typography>
                {getDataSourceConfigurationComponent && getDataSourceConfigurationComponent()}
                {onUploadClick && (
                  <div
                    className="text-primary-active flex cursor-pointer items-center gap-2 self-center text-lg font-semibold"
                    onClick={onUploadClick}
                  >
                    <Icon name="icon-upload"></Icon>
                    <span>Upload</span>
                  </div>
                )}
              </div>
              <div className="flex flex-col-reverse gap-3 sm:flex-row sm:gap-0">
                {isFiltering && (
                  <LegacyButton
                    rounded="full"
                    variant="outlined"
                    color="primaryActive"
                    border="primaryActive"
                    className="sm:mx-8"
                    startIcon={<Icon name="cancel" />}
                    onClick={clearFilters}
                  >
                    {t('ClearFilters')}
                  </LegacyButton>
                )}
                {!user.data?.roleHelper.isFirstReader && !user.data?.roleHelper.isSecondReader && (
                  <LegacyButton
                    variant="contained"
                    color="light"
                    border="light"
                    className="mr-3"
                    onClick={() =>
                      modals.open({
                        children: <NewVisitModal />,
                        size: 1050,
                      })
                    }
                  >
                    Новое посещение
                  </LegacyButton>
                )}
                <div className="flex">
                  <Typography
                    variant="h4"
                    className="mr-2"
                    data-cy={'num-studies'}
                  >
                    {numOfStudies}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="text-common-light self-end pb-1"
                  >
                    {t('Studies')}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="static -top-1 z-10 border-b-4 border-black">
        <div className="bg-primary-dark pt-3 pb-3 ">
          <InputGroup
            inputMeta={filtersMeta}
            values={filterValues}
            onValuesChange={onChange}
            sorting={filterSorting}
            onSortingChange={setFilterSorting}
            isSortingEnabled={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

StudyListFilter.propTypes = {
  filtersMeta: PropTypes.arrayOf(
    PropTypes.shape({
      /** Identifier used to map a field to it's value in `filterValues` */
      name: PropTypes.string.isRequired,
      /** Friendly label for filter field */
      displayName: PropTypes.string.isRequired,
      /** One of the supported filter field input types */
      inputType: PropTypes.oneOf([
        'Text',
        'MultiSelect',
        'DateRange',
        'None',
        'extraStatus',
        'checkboxGroup',
      ]).isRequired,
      isSortable: PropTypes.bool.isRequired,
      /** Size of filter field in a 12-grid system */
      gridCol: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).isRequired,
      /** Options for a "MultiSelect" inputType */
      option: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  filterValues: PropTypes.object.isRequired,
  numOfStudies: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  isFiltering: PropTypes.bool.isRequired,
  onUploadClick: PropTypes.func,
  getDataSourceConfigurationComponent: PropTypes.func,
};

export default StudyListFilter;
